/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

          jQuery(function() {
              jQuery('.match-height').matchHeight();
          });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'personal_statement': {
      init: function() {
        // JavaScript to be fired on the about us page

          function drawProgressBar(selector, len, overall) {

              var other, otherPercent, thisPercent, limit;

              limit = parseInt(jQuery('.'+selector+'_suggested').html());

              other = overall - len;

              otherPercent = (100 / 4000 ) * other;

              thisPercent = (100 / limit) * len;

              /*if ((otherPercent + thisPercent) > 100) {
                  otherPercent = 100 - thisPercent;
              }

              if (otherPercent > 100) { otherPercent = 100; }*/

              if (thisPercent > 100) { thisPercent = 100; }

              /*jQuery('.'+selector+'_bar .other').css('width',otherPercent+'%');*/
              jQuery('.'+selector+'_bar .this').css('width',thisPercent+'%');

              if (len > limit) {
                  jQuery('.' + selector + '_data .current').css('color','#e32237');
              } else {
                  jQuery('.' + selector + '_data .current').css('color','#333333');
              }


              if (len > 0) {
                  jQuery('.' + selector + '_data .current').html(len);
              } else {
                  jQuery('.' + selector + '_data .current').html('0');
              }

          }

          function updateOverall(overall) {

              var overallPercent;

              if (overall > 4000) {
                  jQuery('.submission .submission-btn').css({"background-color": "#cccccc","border-color": "#cccccc"}).html('SUBMIT');
              } else {
                  jQuery('.submission .submission-btn').css({"background-color": "#e32236","border-color": "#d11a2e"}).html('SUBMIT');
              }

              overallPercent = (100 / 4000) * overall;

              jQuery('.submission .overall').css('width',overallPercent + '%');
              jQuery('.submission .current').html(overall);

          }

          function textareaLoop() {
              var textareas = [], fieldid, overall = 0, i = 0, loop = 0;
              jQuery('textarea').each(function() {
                  fieldid = jQuery(this).attr('id');
                  textareas[i] = {total:jQuery(this).val().length, selector:fieldid.replace("_textarea","")};
                  overall = overall + jQuery(this).val().length;
                  i++;
              });

              while (loop < 6) {
                drawProgressBar(textareas[loop]['selector'], textareas[loop]['total'], overall);
                updateOverall(overall);
                loop++;
              }

              return overall;

          }

          textareaLoop();
          jQuery('textarea').keyup(function () {
             textareaLoop();
          });


          function doTheSubmission() {

              var form_data   = "action=main_submission";

              jQuery('.submit .btn').css('disabled','disabled').html('<i class="fa fa-cog fa-spin fa-fw"></i>');
              jQuery('.ajax-form button').click();

              setTimeout(function(){

                  jQuery.ajax({

                      type: 'POST',

                      url: theme_ajax.url,

                      data: form_data,

                      success: function(data, textStatus, XMLHttpRequest) {

                          var results = jQuery.parseJSON(data);

                          if (results.status === "success") {
                              jQuery('.submit .btn').html('<i class="fa fa-check" aria-hidden="true"></i>');
                          }

                          window.location.replace("/statement-complete/");

                      },

                      error: function(MLHttpRequest, textStatus, errorThrown) {
                          console.log(MLHttpRequest);
                          console.log(textStatus);
                          console.log(errorThrown);
                          alert('Error, please contact support.');

                      }

                  });

              }, 2000);







          }

          jQuery('.submission button').on('click', function(event) {

              // check there is entries in 5 of the fields
              var fieldone, fieldtwo, fieldthree, fieldfour, fieldfive;

              fieldone = jQuery('#your_subject_interest_textarea').val();
              fieldtwo = jQuery('#your_work_experience_textarea').val();
              fieldthree = jQuery('#your_hobbies_and_interests_textarea').val();
              fieldfour = jQuery('#your_achievements_textarea').val();
              fieldfive = jQuery('#your_career_goals_textarea').val();

              var fielderrors = '';

              if (fieldone === '') {
                  fielderrors = fielderrors + "Your Subject Interest<br>";
              }
              if (fieldtwo === '') {
                  fielderrors = fielderrors + "Your Work Experience<br>";
              }
              if (fieldthree === '') {
                  fielderrors = fielderrors + "Your Hobbies and Interests<br>";
              }
              if (fieldfour=== '') {
                  fielderrors = fielderrors + "Your Achievements<br>";
              }
              if (fieldfive === '') {
                  fielderrors = fielderrors + "Your Career Goals<br>";
              }

              if (fielderrors === '') {

                  // check overall
                  var overall = textareaLoop();
                  if (overall > 4000) {

                      jQuery.confirm({
                          title: 'Submission',
                          content: 'It looks like you have exceeded the character count. Please edit your statement so that it is 4,000 characters or less before clicking submit.',
                          type: 'red',
                          typeAnimated: true,
                          buttons: {
                              close: function () {
                              }
                          }
                      });

                  } else {

                      jQuery.confirm({
                          title: 'Submission',
                          content: 'Once submitted you will not be able to edit your Personal Statement until it has been assessed. Only proceed if your entry is complete.',
                          type: 'red',
                          typeAnimated: true,
                          buttons: {
                              tryAgain: {
                                  text: 'Submit',
                                  btnClass: 'btn-red',
                                  action: function () {
                                      doTheSubmission();
                                  }
                              },
                              close: function () {
                              }
                          }
                      });

                  }

              } else {

                  jQuery.confirm({
                      title: 'Submission',
                      content: 'It looks like you have missed a section. Please enter content in the following sections:<br>' + fielderrors,
                      type: 'red',
                      typeAnimated: true,
                      buttons: {
                          close: function () {
                          }
                      }
                  });


              }



          });



          jQuery('body').on('submit', '.ajax-form', function(event) {
              event.preventDefault();

              var selector    = jQuery(this).attr('id');
              var form_data   = jQuery(this).serialize() + "&action=statement" + "&selector=" + selector;

              jQuery('.'+selector+'_btn').attr('disabled','disabled').html('SAVING <i class="fa fa-cog fa-spin fa-fw"></i>');

              jQuery.ajax({

                  type: 'POST',

                  url: theme_ajax.url,

                  data: form_data,

                  success: function(data, textStatus, XMLHttpRequest) {

                      var results = jQuery.parseJSON(data);

                      if (results.status === "success") {
                          jQuery('.'+selector+'_btn').removeAttr('disabled').html('SUCCESS <i class="fa fa-check" aria-hidden="true"></i>');
                          setTimeout(function(){
                              jQuery('.'+selector+'_btn').html('SAVE');
                          }, 2000);
                      }

                  },

                  error: function(MLHttpRequest, textStatus, errorThrown) {
                      console.log(MLHttpRequest);
                      console.log(textStatus);
                      console.log(errorThrown);
                      alert('Error, please contact support.');

                  }

              });

          });

      }
    },
    // About us page, note the change from about-us to about_us.
    'profile': {
      init: function() {
        // JavaScript to be fired on the about us page
          jQuery(".chosen-select").chosen();

          jQuery(window).resize(function () {
              var width = jQuery(".ginput_container_select")[0].offsetWidth + "px";
              jQuery(".ginput_container_select .chosen-container").css("width", width);
          });
      }
    },
    // About us page, note the change from about-us to about_us.
    'register': {
      init: function() {
        // JavaScript to be fired on the about us page
          jQuery(".chosen-select").chosen();

          jQuery(window).resize(function () {
              var width = jQuery(".ginput_container_select")[0].offsetWidth + "px";
              jQuery(".ginput_container_select .chosen-container").css("width", width);
          });
      }
    },
    // Login page
    'login': {
        init: function () {

            jQuery("#wp-submit").click(function () {
                var user = jQuery("input#loginemail").val();
                var pass = jQuery("input#loginpass").val();
                if (user === "") {
                    jQuery("input#loginemail").focus();
                    return false;
                }
                if (pass === "") {
                    jQuery("input#loginpass").focus();
                    return false;
                }
            });


        }
    },
    'password': {
        init: function () {

            // for lost password
            jQuery("form#lostPasswordForm").submit(function () {
                var submit = jQuery("div#lostPassword #wp-submit"),
                    //preloader = jQuery("div#lostPassword #preloader"),
                    message = jQuery("div#lostPassword #message"),
                    contents = {
                        action: 'lost_pass',
                        nonce: this.user_lost_password_nonce.value,
                        user_login: this.user_login.value
                    };

                // disable button onsubmit to avoid double submision
                submit.attr("disabled", "disabled").addClass('disabled');

                // Display our pre-loading
                //preloader.css({'visibility':'visible'});

                jQuery.post(theme_ajax.url, contents, function (data) {
                    submit.removeAttr("disabled").removeClass('disabled');

                    // hide pre-loader
                    //preloader.css({'visibility':'hidden'});

                    // display return data
                    message.html(data);
                });

                return false;
            });


            // for reset password
            jQuery("form#resetPasswordForm").submit(function () {
                var submit = jQuery("div#resetPassword #wp-submit"),
                    //preloader = jQuery("div#resetPassword #preloader"),
                    message = jQuery("div#resetPassword #message"),
                    contents = {
                        action: 'reset_pass',
                        nonce: this.user_reset_password_nonce.value,
                        pass1: this.pass1.value,
                        pass2: this.pass2.value,
                        user_key: this.user_key.value,
                        user_login: this.user_login.value
                    };

                // disable button onsubmit to avoid double submision
                submit.attr("disabled", "disabled").addClass('disabled');

                // Display our pre-loading
                //preloader.css({'visibility':'visible'});

                jQuery.post(theme_ajax.url, contents, function (data) {
                    submit.removeAttr("disabled").removeClass('disabled');

                    // hide pre-loader
                    //preloader.css({'visibility':'hidden'});

                    // display return data
                    message.html(data);
                });

                return false;
            });


        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

